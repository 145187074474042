import React from 'react'

import Head from '@components/Head'
import {
  Hero,
  Logos,
  Section1,
  Section2,
  ClientSuccess,
  CTA,
} from '@components/pages/lp/sharepoint-consulting'
import MoreLinksSection from '@components/pages/lp/MoreLinksSection'

const SharePointConsultingLp = () => {
  return (
    <>
      <Head
        title="SharePoint Services Toronto"
        description="Microsoft SharePoint services for business in Toronto. Experts in Microsoft Cloud solutions, SharePoint intranets/portals, SharePoint development, SharePoint migrations and more."
      />
      <Hero />
      <Logos />
      <Section1 />
      <ClientSuccess />
      <Section2 />
      <CTA />
      <MoreLinksSection />
    </>
  )
}
export default SharePointConsultingLp
